<template>
  <div class="wrapper">
    <el-carousel class="slideBox" height="100%" :interval="5000" arrow="never">
      <!-- <el-carousel-item> -->
      <div class="slideBox1">
        <div class="title1 animate__animated animate__bounceInRight">高精度音频定位技术提供商</div>
        <div class="title2 animate__animated animate__bounceInLeft">沧穹科技</div>
        <div class="title3"><img src="/images/home/f1.gif" style="width: 21px; height: 34px; z-index: 1" /></div>
      </div>
      <!-- </el-carousel-item> -->
    </el-carousel>
    <div class="wrapper-content">
      <div class="row_content">
        <div class="content">
          <div class="title"><img src="/images/home/No_1.png" style="float: left" /></div>
          <div class="description">
            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  >沧穹科技成立于2020年10月，是由<span style="color: #1d953f"><strong>武汉大学</strong></span
                  >发起，国家级高新区湖州<span style="color: #1d953f"><strong>莫干山高新技术产业开发区</strong></span
                  >支持引进，落户于<span style="color: #1d953f"><strong>德清地理信息小镇</strong></span
                  >的高新技术人才创新产业化机构。</span
                ></span
              >
            </p>

            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  >沧穹科技团队由芬兰科学与人文院院士、武汉大学测绘遥感信息工程国家重点实验室主任<span style="color: #1d953f"><strong>陈锐志</strong></span
                  >教授领衔，旨在将国家科技部“十三五”重点研发计划项目<span style="color: #1d953f"><strong>“高可用高精度室内智能混合定位与室内GIS技术”</strong></span
                  >及其相关的优秀科技成果进行产业孵化，为我国研发并产业化国际领先的室内定位技术创造可能。</span
                ></span
              >
            </p>

            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  ><span style="text-align: justify"
                    >沧穹科技研发的<span style="color: #1d953f"><strong>基于音频的高精度室内定位技术</strong></span
                    >，突破了消费级智能终端高精度定位“卡脖子”关键技术，拥有<span style="color: #1d953f"><strong>完全自主的核心知识产权</strong></span
                    >，具备广阔的市场前景。</span
                  ></span
                ></span
              >
            </p>
            <p style="text-align: justify">&nbsp;</p>
          </div>
          <div class="content_col">
            <div class="col_3 content1_c1" @mouseover="mouseover('1_1')" @mouseleave="mouseleave">
              <div class="content1_img"><img src="/images/home/1_1.png" /></div>
              <div class="content1_t1">顶尖团队</div>
              <div class="content1_t2">院士专家领军<br />团队硕博比例90%以上<br />专注于产品的研发和测试</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '1_1'" class="content_b"></div>
              </transition>
            </div>
            <div class="col_3 content1_c1" @mouseover="mouseover('1_2')" @mouseleave="mouseleave">
              <div class="content1_img"><img src="/images/home/1_2.png" /></div>
              <div class="content1_t1">自主技术</div>
              <div class="content1_t2">核心自主创新<br />音频定位技术自主可控<br />RISC-V芯片架构开源可控</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '1_2'" class="content_b"></div>
              </transition>
            </div>
            <div class="col_3 content1_c1" @mouseover="mouseover('1_3')" @mouseleave="mouseleave">
              <div class="content1_img"><img src="/images/home/1_3.png" /></div>
              <div class="content1_t1">政府支持</div>
              <div class="content1_t2">国家项目孵化<br />依托科技部“十三五”专项<br />莫干山高新区引进落地德清地理信息小镇</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '1_3'" class="content_b"></div>
              </transition>
            </div>
          </div>
          <div style="height: 40px"></div>
        </div>
      </div>
      <div class="row_content">
        <div class="content">
          <div class="title"><img src="/images/home/No_2.png" style="float: right" /></div>
          <div class="content">
            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  >沧穹科技研发的基于音频的高精度室内定位技术，具有<strong><span style="color: #1d953f">自主可控、精准定位、容易布设、手机接入、无限并发、保护隐私</span></strong
                  >的六大特性。<strong><span style="color: #1d953f">自主可控</span></strong
                  >：核心IP全自主研发，RISC-V架构开源可控。<strong><span style="color: #1d953f">精准定位</span></strong
                  >：高精度室内定位，精度可达分米级。<strong><span style="color: #1d953f">容易布设</span></strong
                  >：基站通电即可用，单基站可覆盖30-50米。<strong><span style="color: #1d953f">手机接入</span></strong
                  >：消费级技术，任意智能手机终端可用。<strong><span style="color: #1d953f">无限并发</span></strong
                  >：广播模式，基站终端无需交互，不限容量。<strong><span style="color: #1d953f">保护隐私</span></strong
                  >：终端解算，用户自主选择是否共享位置。</span
                ></span
              ><br />
              &nbsp;
            </p>
          </div>
          <div class="content_col">
            <div class="col_3">
              <div class="content2_c1">
                <div class="title">自主可控</div>
                <div class="subTitle">核心IP全自主研发，RISC-V架构开源可控</div>
              </div>
              <div class="content2_c1">
                <div class="title">手机接入</div>
                <div class="subTitle">消费级技术，任意智能手机终端可用</div>
              </div>
            </div>
            <div class="col_3">
              <div class="content2_c1">
                <div class="title">精准定位</div>
                <div class="subTitle">高精度室内定位，精度可达分米级</div>
              </div>
              <div class="content2_c1">
                <div class="title">无限并发</div>
                <div class="subTitle">广播模式，基站终端无需交互、不限容量</div>
              </div>
            </div>
            <div class="col_3">
              <div class="content2_c1">
                <div class="title">容易布设</div>
                <div class="subTitle">基站通电即用，单基站可覆盖30-50米</div>
              </div>
              <div class="content2_c1">
                <div class="title">保护隐私</div>
                <div class="subTitle">终端解算，用户自主选择是否共享位置</div>
              </div>
            </div>
          </div>
          <div style="height: 40px"></div>
        </div>
      </div>
      <div class="row_content">
        <div class="content">
          <div class="title"><img src="/images/home/No_3.png" style="float: left" /></div>
          <div class="content">
            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  >沧穹科技为音频室内定位技术提供商，专注于核心IP及芯片研发，可为下游合作伙伴提供底层软硬件产品及技术<span style="text-align: justify">支</span>持服务，产品主要包括<strong><span style="color: #1d953f">芯片、模组、开发板</span></strong
                  ><span style="text-align: justify">和</span
                  ><strong
                    ><span style="color: #1d953f"><span style="text-align: justify">SDK</span></span></strong
                  >，并可为各类行业定位场景集成服务商的技术支持服务。</span
                ></span
              >
            </p>
          </div>
          <div class="content_col">
            <div class="col_4 content3" @mouseover="mouseover('3_1')" @mouseleave="mouseleave">
              <div class="content3_img"><img src="/images/home/3_1.png" /></div>
              <div class="content3_t1">芯片</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '3_1'" class="content_b"></div>
              </transition>
            </div>
            <div class="col_4 content3" @mouseover="mouseover('3_2')" @mouseleave="mouseleave">
              <div class="content3_img"><img src="/images/home/3_2.png" /></div>
              <div class="content3_t1">模组</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '3_2'" class="content_b"></div>
              </transition>
            </div>
            <div class="col_4 content3" @mouseover="mouseover('3_3')" @mouseleave="mouseleave">
              <div class="content3_img"><img src="/images/home/3_3.png" /></div>
              <div class="content3_t1">开发板</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '3_3'" class="content_b"></div>
              </transition>
            </div>
            <div class="col_4 content3" @mouseover="mouseover('3_4')" @mouseleave="mouseleave">
              <div class="content3_img"><img src="/images/home/3_4.png" /></div>
              <div class="content3_t1">SDK</div>
              <transition name="el-zoom-in-bottom">
                <div v-show="showContentB == '3_4'" class="content_b"></div>
              </transition>
            </div>
          </div>
          <div style="height: 70px"></div>
        </div>
      </div>
      <div class="row_content">
        <div class="content">
          <div class="title"><img src="/images/home/No_4.png" style="float: right" /></div>
          <div class="content">
            <p style="text-align: justify">
              <span style="line-height: 2"
                ><span style="font-size: 14px"
                  >沧穹科技产业定位为音频室内定位技术提供商，<span style="color: #1d953f"><strong>专注于上游核心技术研发</strong></span
                  >，努力与产业生态上下游合作伙伴一起，推动音频高精度定位技术进入实际场景应用，让其成为室内定位强有力的技术路径选择，并成为北斗系统在室内环境中的重要补充。</span
                ></span
              >
            </p>
          </div>
          <div style="margin: 10px 0">
            <img src="/images/home/p_1.jpg" style="width: 100%" />
          </div>
          <div style="height: 100%; word-wrap: break-word; margin: 120px 0 200px 0">
            <p style="text-align: center">
              <span style="font-size: 30px"
                ><strong>诚挚欢迎</strong
                ><span style="line-height: 2"
                  ><span style="color: #1d953f"><strong>产业生态伙伴</strong></span></span
                ><strong>与我们合作</strong></span
              >
            </p>
            <p style="text-align: center">
              <span style="color: #000000"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">WE LOO</span></span></span
              ><span style="color: #1d953f"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">K</span></span></span
              ><span style="color: #000000"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">ING FOR</span></span></span
              ><span style="color: #1d953f"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">W</span></span></span
              ><span style="color: #000000"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">ARD TO BE</span></span></span
              ><span style="color: #1d953f"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">C</span></span></span
              ><span style="color: #000000"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">OME A PA</span></span></span
              ><span style="color: #1d953f"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">R</span></span></span
              ><span style="color: #000000"
                ><span style="font-family: Arial Black"><span style="font-size: 14px">TNER</span></span></span
              >
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script scoped>
export default {
  data() {
    return {
      showContentB: -1,
    };
  },
  mounted() {
    
  },
  methods: {
    mouseover(id) {
      this.showContentB = id;
    },
    mouseleave() {
      this.showContentB = -1;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "home.scss";
.animate__animated.animate__bounceInRight ,.animate__animated.animate__bounceInLeft{
  --animate-duration: 2s;
}
</style>
